/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import {
  URL_BAD_ATTITUDE_DEMONSTRATION_TYPES,
  URL_BAD_ATTITUDE_PERSON_TYPES,
  URL_BAD_ATTITUDE_TYPES,
} from '../constants'
import { VerticalSpace } from '../../../space/vertical-space'
import { Title } from '../../../typography'
import { Checkbox, Form } from 'antd'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'
import AttitudeTypes from './attitude-types'
import { COLORS } from '../../../../helpers/constants'

const createName = (id) => `item${id}`

const PersonTypesForm = () => {
  const { data } = useGetQuestions(URL_BAD_ATTITUDE_PERSON_TYPES)
  const { data: types } = useGetQuestions(URL_BAD_ATTITUDE_TYPES)
  const { data: demoTypes } = useGetQuestions(
    URL_BAD_ATTITUDE_DEMONSTRATION_TYPES,
  )
  const form = Form.useFormInstance()

  const [checkedIds, setCheckedIds] = useState({})

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdBadAttitudePersonTypes', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['householdBadAttitudePersonTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['householdBadAttitudePersonTypes', itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          {item.id === 1 && checkedIds[itemName] && (
            <AttitudeTypes
              parentFormItem={['householdBadAttitudePersonTypes', itemName]}
            />
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <React.Fragment>
      <Title style={{ margin: '12px 0' }}>
        Ո՞ւմ կողմից է դրսևորվել նման վերաբերմունք
        <span
          style={{
            color: COLORS.ALERT.RED,
            marginLeft: '5px',
            fontWeight: 100,
          }}
        >
          *
        </span>
      </Title>
      <FormItem name="householdBadAttitudePersonTypes">
        {renderItems(data)}
      </FormItem>
    </React.Fragment>
  )
}

export default PersonTypesForm
