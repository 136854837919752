import React, { useCallback, useState } from 'react'
import { Checkbox, Col, Form, Row, Spin } from 'antd'
import { FormItem } from '../../../form/form-item'
import { FormSectionCard } from '../../../card/form-section-card'
import { URL_GET_HEALTH_NEED } from '../constants'
import useGetQuestions from '../../../../api/application/use-get-questions'
import ProfessionalSupportChild from '../professional-support-needs/professional-support-child'
import SnSelect from './sn-select'
import SnInput from './sn-input'

const ProfessionalSupportNeeds = ({ dataFamily }) => {
  const form = Form.useFormInstance()
  const [checked, setChecked] = useState(false)
  const [checkedFamily, setCheckedFamily] = useState(true)

  const [disable, setDisable] = useState(false)
  const [disableLast, setDisableLast] = useState(false)
  const [professionalSupportNeedsValue, setProfessionalSupportNeedsValue] =
    useState([])

  const { data: supportneeds, isLoading } = useGetQuestions(URL_GET_HEALTH_NEED)

  const selectProfessionalSupport = Form.useWatch('professionalSupport', {
    preserve: true,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeProfessionalSupport = useCallback((checked, row) => {
    const updatedProfSupportNeeds = {
      id: row.id,
      comment: `pharmacyTypeInput${row.id}` || null, // Set comment or null if no value provided
    }

    const numChecked = professionalSupportNeedsValue.length

    if (row.id === 1 && checked === true) {
      setDisableLast(true)
      setDisable(false)
    } else {
      setDisableLast(false)
      setDisable(true)
    }

    if (numChecked < 2 && checked === false) {
      setDisableLast(false)
      setDisable(false)
    }

    form.setFieldsValue({
      personHealthNeeds: checked
        ? [
            ...form.getFieldValue('personHealthNeeds'),
            { personId: null, healthNeedId: row.id, comment: null },
          ]
        : form
            .getFieldValue('personHealthNeeds')
            .filter((r) => r.healthNeedId !== row.id),
    })

    if (checked === false) {
      // eslint-disable-next-line no-unused-vars
      supportneeds?.map((needsVal, needsIndex) => {
        if (needsVal.id === row.id && needsVal?.children?.length > 0) {
          // eslint-disable-next-line no-unused-vars
          needsVal?.children?.map((valueChildren, indexChild) => {
            form.setFieldsValue({
              personHealthNeeds: form
                .getFieldValue('personHealthNeeds')
                .filter((r) => r.healthNeedId !== valueChildren.id),
            })
          })
        }
      })

      form.resetFields([
        `professionalSupportInput${row.id}`,
        `selectSelectionSupportFamilyData${row.id}`,
        `selectionSupport${row.id}`,
      ])

      row.children.map((rowChildren) => {
        form.resetFields([
          `selectionSupportFamilyData${rowChildren.id}`,
          `professionalSupportInput${rowChildren.id}`,
        ])
      })
    }

    setProfessionalSupportNeedsValue((prevState) => {
      // Check if the checkbox is checked or unchecked
      if (checked) {
        // If checked, update or add the item
        const existingIndex = prevState.findIndex(
          (action) => action.id === row.id,
        )

        if (existingIndex !== -1) {
          // If the item already exists, update it
          const updatedActions = [...prevState]
          updatedActions[existingIndex] = updatedProfSupportNeeds
          return updatedActions
        } else {
          // If the item doesn't exist, add it
          return [...prevState, updatedProfSupportNeeds]
        }
      } else {
        form.resetFields([
          `professionalSupportInput${row.id}`,
          `selectSelectionSupportFamilyData${row.id}`,
        ])
        // If unchecked, remove the item
        return prevState.filter((action) => action.id !== row.id)
      }
    })
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCheckFamilyData = useCallback((checkedFamily, row) => {
    const arrFamilyData = []
    dataFamily?.map((dataFamilyVal) => {
      arrFamilyData.push(dataFamilyVal.id)
    })

    const index = form
      .getFieldValue('personHealthNeeds')
      .findIndex((r) => r.healthNeedId === row.id)
    const newData = [...form.getFieldValue('personHealthNeeds')]

    if (checkedFamily === true) {
      form.setFieldsValue({
        [`selectSelectionSupportFamilyData${row.id}`]: arrFamilyData,
      })

      newData[index].personId = arrFamilyData
      form.setFieldsValue({ personHealthNeeds: newData })
    } else {
      form.setFieldsValue({
        [`selectSelectionSupportFamilyData${row.id}`]: undefined,
      })

      newData[index].personId = undefined
      form.setFieldsValue({ personHealthNeeds: newData })
    }
  })

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Որո՞նք են Ձեր/Ձեր ընտանիքի անդամների մասնագիտական աջակցության (ծառայությունների) կարիքները"
        showRequired={true}
      >
        <FormItem name="personHealthNeeds" hidden initialValue={[]} />
        <FormItem name={['professionalSupport']} rules={[{ required: true }]}>
          {supportneeds?.map((row) => (
            <FormItem name={['professionalSupport', row.label]} key={row.id}>
              <FormItem
                name={['professionalSupport', row.label, 'id']}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={row.id !== 1 ? disableLast : disable}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    onChangeProfessionalSupport(e.target.checked, row)
                  }}
                  value={row.id}
                >
                  {row.name}
                </Checkbox>
              </FormItem>
              {selectProfessionalSupport?.[row.label]?.id ? (
                row.children.length > 0 && row.hasComment === false ? (
                  <ProfessionalSupportChild dataFamily={dataFamily} row={row} />
                ) : row.children.length === 0 && row?.hasComment === false ? (
                  row.id !== 1 && (
                    <>
                      <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                          <SnSelect
                            nameItem={`selectSelectionSupportFamilyData${row.id}`}
                            dataFamily={dataFamily}
                            row={row}
                            style={{ marginLeft: '25px' }}
                          />
                          <FormItem
                            name={[
                              'professionalSupportFamilyAllData',
                              row.label,
                              'id',
                            ]}
                            valuePropName="checked"
                          >
                            <Checkbox
                              checked={checkedFamily}
                              onChange={(e) => {
                                setCheckedFamily(e.target.checked)
                                setCheckFamilyData(e.target.checked, row)
                              }}
                              value={row.id}
                              style={{ marginLeft: '25px' }}
                            >
                              Ընտանիքի բոլոր անդամներն ունեն տվյալ կարիքը
                            </Checkbox>
                          </FormItem>
                        </Col>
                      </Row>
                    </>
                  )
                ) : row.children.length === 0 && row?.hasComment === true ? (
                  <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <SnInput
                        nameItem={`professionalSupportInput${row.id}`}
                        row={row}
                        style={{ marginLeft: '25px' }}
                      />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <SnSelect
                        nameItem={`selectSelectionSupportFamilyData${row.id}`}
                        dataFamily={dataFamily}
                        row={row}
                        style={{ marginLeft: '25px' }}
                      />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <FormItem
                        name={[
                          'professionalSupportFamilyAllData',
                          row.label,
                          'id',
                        ]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          checked={checkedFamily}
                          onChange={(e) => {
                            setCheckedFamily(e.target.checked)
                            setCheckFamilyData(e.target.checked, row)
                          }}
                          value={row.id}
                          style={{ marginLeft: '25px' }}
                        >
                          Ընտանիքի բոլոր անդամներն ունեն տվյալ կարիքը
                        </Checkbox>
                      </FormItem>
                    </Col>
                  </Row>
                ) : (
                  <FormItem hidden />
                )
              ) : (
                <FormItem hidden />
              )}
            </FormItem>
          ))}
        </FormItem>
      </FormSectionCard>
    </Spin>
  )
}

export default ProfessionalSupportNeeds
