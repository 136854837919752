import React, { useEffect } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'
import FirstStepForm from '../../components/fill-application/first-step'
import SecondStepForm from '../../components/fill-application/second-step'
import ThirdStepForm from '../../components/fill-application/third-step'
import ForthStepForm from '../../components/fill-application/forth-step'
import { FifthStep } from '../../components/fill-application/fifth-step'
import EighthStepForm from '../../components/fill-application/eighth-step'
import { SeventhStep } from '../../components/fill-application/seventh-step'
import SixthStep from '../../components/fill-application/sixth-step'
import { NinthStep } from '../../components/fill-application/ninth-step'
// import { ThirteenthStep } from '../../components/fill-application/thirteenth-step'
import EleventhStep from '../../components/fill-application/eleventh-step'
import { TenthStep } from '../../components/fill-application/tenth-step'
import TwelfthStepForm from '../../components/fill-application/twelfth-step'
import FourteenStep from '../../components/fill-application/fourteen-step'
import FifteenStep from '../../components/fill-application/fifteen-step'

const ManageSteps = () => {
  const { step, setStep } = useApplicationSteps()

  useEffect(() => {
    if (step === 12) {
      setStep(13)
    }
  }, [step, setStep])

  return (
    <>
      {step === 0 && <FirstStepForm />}
      {step === 1 && <SecondStepForm />}
      {step === 2 && <ThirdStepForm />}
      {step === 3 && <ForthStepForm />}
      {step === 4 && <FifthStep />}
      {step === 5 && <SixthStep />}
      {step === 6 && <SeventhStep />}
      {step === 7 && <EighthStepForm />}
      {step === 8 && <NinthStep />}
      {step === 9 && <TenthStep />}
      {step === 10 && <EleventhStep />}
      {step === 11 && <TwelfthStepForm />}
      {/* {step === 12 && <ThirteenthStep />} */}
      {step === 13 && <FourteenStep />}
      {step === 14 && <FifteenStep />}
    </>
  )
}

export default ManageSteps
