import { Collapse, Radio, Space } from 'antd'
import React, { useEffect } from 'react'
import { ManageFamilyMemberButton } from '../../../button'
import { DeleteOutlined } from '@ant-design/icons'
import { COLORS } from '../../../../helpers/constants'
import NonResideReasons from './non-reside-reasons'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import { Form } from '../../form'
import { PersonMainData } from '../../person-main-data'
import { setFormItemProp } from '../../../../helpers/utils'
import { PersonAddress } from '../../../form/person-address'
import { VerticalSpace } from '../../../space/vertical-space'
import AccommodationType from './accommodation-type'
import FamilyReuniteForm from './family-reunite-form'

const { Panel } = Collapse

const NonResideMemberForm = ({ field, remove, parentFormItem }) => {
  const form = Form.useFormInstance()
  const agreedToGiveInfo = Form.useWatch([
    ...parentFormItem,
    field.name,
    'provideInformation',
  ])

  useEffect(() => {
    form.setFields([
      {
        name: [...parentFormItem, field.name, 'nonReside'],
        value: true,
      },
    ])
  }, [field.name, form, parentFormItem])

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel
        header={'Նույն վայրում չբնակվող ընտանիքի անդամ '}
        key={field.name}
        extra={
          field.name !== 0 && (
            <Space>
              <ManageFamilyMemberButton
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                doAction={() => remove(field.name)}
              />
            </Space>
          )
        }
      >
        <FormItem name={[field.name, 'nonReside']} hidden />
        <VerticalSpace>
          <NonResideReasons field={field} parentFormItem={parentFormItem} />
          <FormItem
            name={[field.name, 'provideInformation']}
            rules={[{ required: true }]}
            label={
              <SecondaryText>
                Խնդրում եմ տրամադրել այլ վայրում բնակվող անձի մասին
                տեղեկատվություն։
              </SecondaryText>
            }
          >
            <Radio.Group>
              <Radio value={true}>
                Պատրաստակամ է տրամադրել տեղեկատվություն
              </Radio>
              <Radio value={false}>
                Չի ցանկանում տրամադրել տեղեկատվություն
              </Radio>
            </Radio.Group>
          </FormItem>
          {agreedToGiveInfo && (
            <>
              <PersonMainData
                title="Անձնական տվյալներ"
                formItemProp={setFormItemProp(field.name)}
                parentFormItem={parentFormItem}
                requireDocs={false}
              />
              <SecondaryText>Նշել կացարանի գտնվելու վայրը</SecondaryText>
              <PersonAddress
                formItemState={[field.name, 'stateId']}
                formItemCommunity={[field.name, 'communityId']}
                formItemAddress={[field.name, 'addressId']}
                parentProp={parentFormItem}
                thiredPage={true}
              />
              <AccommodationType
                parentFormItem={parentFormItem}
                field={field}
              />
            </>
          )}
        </VerticalSpace>
        <FamilyReuniteForm field={field} parentFormItem={parentFormItem} />
      </Panel>
    </Collapse>
  )
}

export default NonResideMemberForm
