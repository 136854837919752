// export const URL_DATA_OF_CURRENT_RESIDANCE =
//   'api/CurrentLocality/GetAccommodationType'
// export const URL_ACCOMMONDATION_USE_TYPE =
//   'api/CurrentLocality/GetAccommodationUseType'
// export const URL_ACCOMMONDATION_STAY_PERIOD =
//   'api/CurrentLocality/GetAccommodationStayPeriod'
// export const URL_ACCOMMONDATION_RISK_TYPE =
//   'api/CurrentLocality/GetAccommodationRiskType'

// export const URL_BUILDING_TYPE = 'api/CurrentLocality/GetBuildingType'
// export const URL_NON_CAN_GO_THERE_REASON =
//   'api/CurrentLocality/GetNonCanGoThereReason'
// export const URL_NON_SAFE_REASONS = 'api/CurrentLocality/GetNonSafeReasons'
export const URL_DATA_OF_CURRENT_RESIDANCE = 'api/Resource/GetAccommodationType'
export const URL_ACCOMMONDATION_USE_TYPE =
  'api/Resource/GetAccommodationUseType'
export const URL_ACCOMMONDATION_STAY_PERIOD =
  'api/Resource/GetAccommodationStayPeriod'
export const URL_ACCOMMONDATION_RISK_TYPE =
  'api/Resource/GetAccommodationRiskType'

export const URL_BUILDING_TYPE = 'api/Resource/GetBuildingType'
export const URL_NON_CAN_GO_THERE_REASON = 'api/Resource/GetNonCanGoThereReason'
export const URL_NON_SAFE_REASONS = 'api/Resource/GetNonSafeReasons'
