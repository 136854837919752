import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '../../form/form'
import { Button } from '../../button'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { FormItem } from '../../form/form-item'
import { Select } from '../../select'
import { createName, createOptionsFromArray } from '../../../helpers/utils'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Collapse, Space, Checkbox, Radio } from 'antd'
import { SecondaryText, Title } from '../../typography'
import { COLORS } from '../../../helpers/constants'
import {
  URL_EDUCATION_NEED,
  URL_EDUCATION_NOT_REASON,
  URL_EDUCATION_SUB_TYPES,
  URL_PERIOD,
} from './constants'
import { Input } from '../../input'
import { VerticalSpace } from '../../space/vertical-space'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

export const FamilyMemberEducation = ({
  parentFormItem,
  questionId,
  familyMembers,
}) => {
  return (
    <Form.List name={[...parentFormItem, 'memberEducation']}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ManageFields fields={fields} add={add} />
            {fields.map((field) => (
              <PersonEducation
                key={field.key}
                field={field}
                remove={remove}
                parentFormItem={[...parentFormItem, 'memberEducation']}
                questionId={questionId}
                familyMembers={familyMembers}
              />
            ))}
            <Button
              type="primary"
              size="large"
              shape="round"
              onClick={() => add()}
              style={{ margin: '20px auto' }}
            >
              <PlusCircleOutlined />
              Ավելացնել ընտանիքի անդամ
            </Button>
          </div>
        )
      }}
    </Form.List>
  )
}

const PersonEducation = ({
  field,
  remove,
  parentFormItem,
  questionId,
  familyMembers,
}) => {
  const form = Form.useFormInstance()
  const { data: periods } = useGetQuestions(URL_PERIOD)

  const { data: notAttendedQuestions } = useGetQuestions(
    URL_EDUCATION_NOT_REASON,
  )
  const { data: eduNeedsQuestions } = useGetQuestions(URL_EDUCATION_NEED)
  const { data: eduSubTypes } = useGetQuestions(URL_EDUCATION_SUB_TYPES)

  const [notAttendedReasons, setNotAttendedReasons] = useState({})
  const [eduNeeds, setEduNeeds] = useState({})

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [...parentFormItem, ...parentName, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentFormItem],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, state)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={state[itemName]}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Collapse.Panel
        key={field.name}
        header={'Ընտանիքի անդամի տվյալներ'}
        extra={
          <Space>
            {field.name !== 0 && (
              <Button
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                onClick={() => remove(field.name)}
                type="text"
              >
                Հեռացնել
              </Button>
            )}
          </Space>
        }
      >
        <VerticalSpace>
          <FormItem
            name={[field.name, 'personId']}
            rules={[{ required: true }]}
            label={<SecondaryText>Ընտրել ընտանիքի անդամին</SecondaryText>}
          >
            <Select options={createOptionsFromArray(familyMembers)} />
          </FormItem>
          {questionId !== 6 && (
            <>
              <FormItem
                name={[
                  ...parentFormItem,
                  field.name,
                  'personEducationNotAttendReasons',
                ]}
              >
                {renderItems(
                  notAttendedQuestions,
                  0,
                  notAttendedReasons,
                  setNotAttendedReasons,
                  [field.name, 'personEducationNotAttendReasons'],
                )}
              </FormItem>
              <Form.Item
                name={[field.name, 'educationInterruptedPeriodId']}
                rules={[{ required: true }]}
                label={
                  <Title>
                    Ինչքա՞ն ժամանակ է ընդհատվել հաճախումը / ուսուցումը
                  </Title>
                }
              >
                <Radio.Group>
                  <VerticalSpace>
                    {periods.map((period) => (
                      <Radio key={period.id} value={period.id}>
                        {period.name}
                      </Radio>
                    ))}
                  </VerticalSpace>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          {questionId === 6 && (
            <Form.Item
              name={[field.name, 'educationSubtypeId']}
              style={{ marginBottom: 0 }}
              rules={[{ required: true }]}
              label={<Title>Նշել, թե ի՞նչ հաստատությունում</Title>}
            >
              <Radio.Group>
                <VerticalSpace>
                  {eduSubTypes.map((subType) => (
                    <Radio value={subType.id} key={subType.id}>
                      {subType.name}
                    </Radio>
                  ))}
                </VerticalSpace>
              </Radio.Group>
            </Form.Item>
          )}
          <FormItem
            name={[...parentFormItem, field.name, 'householdEducationNeeds']}
            // rules={[{ required: true }]}
            label={
              <Title>
                <span
                  style={{
                    color: COLORS.ALERT.RED,
                    marginRight: '5px',
                    fontWeight: 100,
                  }}
                >
                  *
                </span>
                Կրթական գործընթացն արդյունավետ կազմակերպելու նպատակով ի՞նչ
                կարիքներ ունի Ձեր ընտանիքի նշված անդամը
              </Title>
            }
          >
            {renderItems(eduNeedsQuestions, 0, eduNeeds, setEduNeeds, [
              field.name,
              'householdEducationNeeds',
            ])}
          </FormItem>
        </VerticalSpace>
      </Collapse.Panel>
    </Collapse>
  )
}

export default PersonEducation
