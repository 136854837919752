import React, { useState, useCallback } from 'react'
import { Col, Form, Row, Spin } from 'antd'
import { FormItem } from '../../../form/form-item'
import { URL_GET_NON_REGISTERED_POLYCLINIC_REASON } from '../constants'
import { COLORS } from '../../../../helpers/constants'
import { Text } from '../../../typography'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { FormSectionCard } from '../../../card/form-section-card'
import { Button } from '../../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Select } from '../../../select'
import NestedCheckboxes from '../../fourteen-step/nested-checkboxes'

const PolyclinicServing = ({ dataFamily, nameItemSelect }) => {
  const form = Form.useFormInstance()
  const [getSelectPs, setSelectPs] = useState(false)
  const { data: nonRegisteredPolyclinicReason, isLoading } = useGetQuestions(
    URL_GET_NON_REGISTERED_POLYCLINIC_REASON,
  )

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdNonRegisteredPolyclinicReasons: [
              ...form.getFieldValue('householdNonRegisteredPolyclinicReasons'),
              { id: row.id, personId: getSelectPs, comment: null },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdNonRegisteredPolyclinicReasons: form
            .getFieldValue('householdNonRegisteredPolyclinicReasons')
            .filter((r) => r.id !== row.id),
        })
      }
    },
    [form, getSelectPs],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonRegisteredPolyclinicReasons')
        .findIndex((r) => r.id === id)
      const newData = [
        ...form.getFieldValue('householdNonRegisteredPolyclinicReasons'),
      ]
      newData[index].comment = value
      form.setFieldsValue({ householdNonRegisteredPolyclinicReasons: newData })
    },
    [form],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps,no-unused-vars
  const changeSelectValue = useCallback((checked, indexRow) => {
    setSelectPs(checked)
  })

  return (
    <Spin spinning={isLoading}>
      <FormItem
        name="householdNonRegisteredPolyclinicReasons"
        hidden
        initialValue={[]}
      />
      <Form.List
        name={`requestMemberItems${nameItemSelect}`}
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => (
            // eslint-disable-next-line react/jsx-key
            <FormSectionCard
              title={
                fields.length > 1 && index !== 0 ? (
                  <Col
                    span={2}
                    style={{
                      alignSelf: 'center',
                      marginBottom: '-10px',
                    }}
                  >
                    <Button
                      style={{ borderRadius: '0' }}
                      onClick={() => {
                        remove(field.name)
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                ) : (
                  <Col
                    span={2}
                    style={{
                      alignSelf: 'center',
                      marginBottom: '-10px',
                    }}
                  >
                    <Button style={{ borderRadius: '0' }} onClick={() => add()}>
                      <PlusOutlined
                        style={{
                          color: COLORS.PRIMARY.BLUE,
                          padding: 0,
                        }}
                      />
                    </Button>
                  </Col>
                )
              }
              showRequired={false}
              style={{ marginBottom: '20px' }}
            >
              <Row gutter={16} key={index}>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  <FormItem
                    name={[field.name, `nameItemSelect${index}`]}
                    style={{ marginLeft: '50px', width: '350px' }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                      onChange={(e) => {
                        changeSelectValue(e, index)
                      }}
                    >
                      {dataFamily?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.firstName + ' ' + item.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  <FormItem
                    name={'reasonsForNotBeing'}
                    rules={[{ required: true }]}
                  >
                    <FormItem
                      name={[field.name, `reasonsForNotBeing${index}`]}
                      style={{ marginLeft: '50px' }}
                      label={
                        <Text color={COLORS.PRIMARY.GRAY_DARK}>
                          Խնդրում եմ նշել՝ որոնք են հաշվառված չլինելու
                          պատճառները
                        </Text>
                      }
                    >
                      {nonRegisteredPolyclinicReason?.map((row) => {
                        return (
                          <FormItem
                            name={[
                              field.name,
                              `reasonsForNotBeingNo${index}`,
                              row.label,
                            ]}
                            // name={[`reasonsForNotBeingNo${index}`, row.label]}
                            key={row.id}
                            style={{ marginLeft: '25px' }}
                            valuePropName="checked"
                          >
                            <FormItem
                              name={`policlinicServingTheAreaInput${row.id}`}
                            >
                              <NestedCheckboxes
                                key={row.id}
                                row={row}
                                level={0}
                                onCheckboxChange={onCheckboxChange}
                                onInputChange={onInputChange}
                              />
                            </FormItem>
                          </FormItem>
                        )
                      })}
                    </FormItem>
                  </FormItem>
                </Col>
              </Row>
            </FormSectionCard>
          ))
        }
      </Form.List>
    </Spin>
  )
}

export default PolyclinicServing
