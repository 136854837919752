import React, { useCallback, useEffect, useState } from 'react'
import { FormItem } from '../../form/form-item'
import { Title } from '../../typography'
import { Checkbox } from 'antd'
import { Form } from '../../form/form'
import { Input } from '../../input'
import { COLORS } from '../../../helpers/constants'

const createName = (id) => `item${id}`

const CurrentWorkDescription = ({ field, questions }) => {
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  // Resetting filed because both WorkNeedsForm and CurrentWorkDescription use same "name"
  useEffect(() => {
    form.resetFields([['personWorks', field.name, 'afterWorkTypes']])
  }, [field.name, form])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const uncheckChildren = (children) => {
    children.forEach((child) => {
      const childName = createName(child.id)
      setCheckedIds((prev) => ({ ...prev, [childName]: false }))
      form.setFields([
        {
          name: ['personWorks', field.name, 'beforeWorkTypes', childName, 'id'],
          value: false,
        },
      ])
      if (child.children) {
        uncheckChildren(child.children)
      }
    })
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, children) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['personWorks', field.name, 'afterWorkTypes', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [
              'personWorks',
              field.name,
              'afterWorkTypes',
              itemName,
              'comment',
            ],
            value: '',
          },
        ])
        if (children) {
          uncheckChildren(children)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, field.name],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'afterWorkTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  item.children,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'afterWorkTypes', itemName, 'comment']}
              label={'Նշել '}
              style={{ marginLeft }}
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }

  return (
    <FormItem
      name={'afterWorkTypes'}
      label={
        <Title>
          Նշել, թե ինչ աշխատանք եք իրականացնում
          <span
            style={{
              color: COLORS.ALERT.RED,
              marginLeft: '5px',
              fontWeight: 100,
            }}
          >
            *
          </span>
        </Title>
      }
    >
      {renderItems(questions)}
    </FormItem>
  )
}

export default CurrentWorkDescription
