import React, { useCallback, useState } from 'react'
import { Form } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_INFORMATION_LANGUAGE } from './constant'
import { SmallInput } from '../../input/small-input'

export const InformationLanguage = () => {
  const { data: questions } = useGetQuestions(URL_GET_INFORMATION_LANGUAGE)

  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  const householdInformationLanguagesValue = []

  form.setFieldsValue({
    householdInformationLanguagesValue: householdInformationLanguagesValue,
  })

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[item.id]) {
        return true
      }
      if (item.children && checkedIds[item.id] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdInformationLanguages', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['householdInformationLanguages', itemName, 'comment'],
            value: '',
          },
        ])
      }

      if (!checked) {
        const uncheckChildren = (parentId) => {
          const parentItem = questions.find((item) => item.id === parentId)
          if (parentItem && parentItem.children) {
            parentItem.children.forEach((child) => {
              const childName = child.id
              setCheckedIds((prevCheckedIds) => ({
                ...prevCheckedIds,
                [childName]: false,
              }))

              form.setFields([
                {
                  name: ['householdInformationLanguages', childName, 'id'],
                  value: false,
                },
                {
                  name: ['householdInformationLanguages', childName, 'comment'],
                  value: '',
                },
              ])

              if (child.children && child.children.length > 0) {
                uncheckChildren(childName)
              }
            })
          }
        }
        uncheckChildren(itemName)
      }
    },
    [form, questions],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdInformationLanguages', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    validateCheckboxGroup(items)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                          ),
                        ),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={['householdInformationLanguages', itemName, 'comment']}
                rules={[{ required: true }]}
                // style={{ marginLeft }}
              >
                <SmallInput size="small" />
              </FormItem>
            )}
          </div>
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard
      title={'Ի՞նչ լեզվով եք ցանկանում ստանալ տեղեկությունները'}
      showRequired={true}
    >
      {renderItems(questions)}
    </FormSectionCard>
  )
}

export default InformationLanguage
