import React, { useCallback, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Checkbox, Col, Form, Radio, Row, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { URL_GET_LANGUAGE } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Select } from '../../select'
import { Input } from '../../input'

const LanguagePart = ({ dataFamily }) => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState(null)

  const { data: dataLanguage, isLoading } = useGetQuestions(URL_GET_LANGUAGE)

  const radioLanguagePart = Form.useWatch('hasForeignLanguage', {
    preserve: true,
  })

  const checkboxLanguage = Form.useWatch('checkboxLanguage', {
    preserve: true,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeLanguage = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        householdLanguages: [
          ...form.getFieldValue('householdLanguages'),
          { languageId: row.id, comment: null, personIds: [] },
        ],
      })
    } else {
      form.setFieldsValue({
        householdLanguages: form
          .getFieldValue('householdLanguages')
          .filter((r) => r.languageId !== row.id),
      })
    }
  })

  const onInputChangeLanguageSelect = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdLanguages')
        .findIndex((r) => r.languageId === id)
      const newData = [...form.getFieldValue('householdLanguages')]
      newData[index].personIds = value
      form.setFieldsValue({
        householdLanguages: newData,
      })
    },
    [form],
  )

  const onInputChangeLanguage = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdLanguages')
        .findIndex((r) => r.languageId === id)
      const newData = [...form.getFieldValue('householdLanguages')]
      newData[index].comment = value
      newData[index].personIds = []
      form.setFieldsValue({ householdLanguages: newData })
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Ընտանիքում կա՞ անձ, ով տիրապետու՞մ է որևէ օտար լեզվի (լավ կամ գերազանց)"
        showRequired={true}
      >
        <FormItem name="householdLanguages" hidden initialValue={[]} />

        <Row>
          <FormItem
            name={'hasForeignLanguage'}
            style={{ width: '100%' }}
            rules={[{ required: true }]}
          >
            <Radio.Group
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
                form.resetFields(['checkboxLanguage'])
                form.setFieldsValue({ householdLanguages: [] })
              }}
              style={{ width: '100%' }}
            >
              <VerticalSpace>
                <Radio value={true}>Այո</Radio>
                {radioLanguagePart === true ? (
                  <FormItem
                    name={['checkboxLanguage']}
                    style={{ marginLeft: '25px' }}
                    rules={[{ required: true }]}
                  >
                    {dataLanguage?.map((row) => {
                      return (
                        <Row key={row.id} style={{ alignItems: 'center' }}>
                          <Col span={8}>
                            <FormItem
                              name={['checkboxLanguage', row.label]}
                              key={row.id}
                              valuePropName="checked"
                            >
                              <Checkbox
                                value={row.id}
                                onChange={(e) => {
                                  setChecked(e.target.checked)
                                  onChangeLanguage(e.target.checked, row)

                                  form.resetFields([['personId', row.label]])

                                  form.resetFields([
                                    `checkboxLanguageInput${row.id}`,
                                  ])
                                }}
                              >
                                {row.name}
                              </Checkbox>
                            </FormItem>
                          </Col>
                          {checkboxLanguage && checkboxLanguage[row.label] ? (
                            row.hasComment === true ? (
                              <Col span={12}>
                                <FormItem
                                  rules={[{ required: true }]}
                                  name={`checkboxLanguageInput${row.id}`}
                                >
                                  <Input
                                    placeholder={'Նշել'}
                                    onChange={(e) =>
                                      onInputChangeLanguage(
                                        e.target.value,
                                        row.id,
                                      )
                                    }
                                  />
                                </FormItem>
                                <FormItem
                                  name={['personId', row.label]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    mode={'multiple'}
                                    placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                                    onChange={(e) => {
                                      onInputChangeLanguageSelect(e, row.id)
                                    }}
                                  >
                                    {dataFamily?.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.firstName + ' ' + item.lastName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            ) : (
                              <Col span={12}>
                                <FormItem
                                  name={['personId', row.label]}
                                  rules={[{ required: true }]}
                                >
                                  <Select
                                    mode={'multiple'}
                                    placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                                    onChange={(e) => {
                                      onInputChangeLanguageSelect(e, row.id)
                                    }}
                                  >
                                    {dataFamily?.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.firstName + ' ' + item.lastName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </FormItem>
                              </Col>
                            )
                          ) : (
                            <FormItem hidden />
                          )}
                        </Row>
                      )
                    })}
                  </FormItem>
                ) : (
                  <FormItem hidden />
                )}
                <Radio value={false}>Ոչ</Radio>
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default LanguagePart
