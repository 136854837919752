/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd'
import { CommunitySelect } from '../select/community-select'
import { RegionsSelect } from '../select/regions-select'
import { AddressSelect } from '../select/address-select'
import { VerticalSpace } from '../space/vertical-space'
import { Form } from './form'
import { FormItem } from './form-item'
import { useEffect } from 'react'
import { Input } from '../input'

export const PersonAddress = ({
  formItemState,
  formItemCommunity,
  formItemAddress,
  showSettlement = true,
  parentProp = [],
  thiredPage,
}) => {
  const stateId = Form.useWatch(
    formItemState ? [...parentProp, ...formItemState] : 'state',
  )
  const communityId = Form.useWatch(
    formItemCommunity ? [...parentProp, ...formItemCommunity] : 'community',
  )

  const form = Form.useFormInstance()

  const onCummunityChange = () => {
    if (showSettlement) {
      form.setFields([
        {
          name:
            (formItemAddress && [...parentProp, ...formItemAddress]) ||
            'address',
          value: null,
        },
      ])
    }
  }

  const onStateChange = () => {
    form.setFields([
      {
        name:
          (formItemCommunity && [...parentProp, ...formItemCommunity]) ||
          'community',
        value: null,
      },
      {
        name:
          (formItemAddress && [...parentProp, ...formItemAddress]) || 'address',
        value: null,
      },
    ])
  }

  return (
    <VerticalSpace>
      <Row gutter={10}>
        <Col sm={8}>
          <FormItem
            label="Մարզ"
            name={formItemState ? [...formItemState] : 'state'}
            rules={[{ required: true }]}
          >
            <RegionsSelect onChange={onStateChange} />
          </FormItem>
        </Col>
        <Col sm={8}>
          <FormItem
            label="Համայնք"
            name={formItemCommunity ? [...formItemCommunity] : 'community'}
            rules={[{ required: true }]}
          >
            <CommunitySelect stateId={stateId} onChange={onCummunityChange} />
          </FormItem>
        </Col>
        {showSettlement && (
          <Col sm={8}>
            <FormItem
              label="Բնակավայր"
              name={formItemAddress ? [...formItemAddress] : 'address'}
              rules={[{ required: true }]}
            >
              <AddressSelect communityId={communityId} />
            </FormItem>
          </Col>
        )}
        {!thiredPage && (
          <Col sm={24}>
            <FormItem
              label="Փողոց, կառույցի տեսակ, կառույցի համար"
              name={formItemAddress ? [...formItemAddress] : 'fullAddress'}
              rules={[
                {
                  pattern: /^[\u0531-\u05870-9\s.,-]*$/,
                  message:
                    'Խնդրում ենք օգտագործել միայն հայերեն տառեր, թվեր և թույլատրված նշաններ',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
      </Row>
    </VerticalSpace>
  )
}
