// export const URL_GET_SUPPORT_DIRECTION =
//   'api/AwarenessPreferences/Page14/GetSupportDirection'
// export const URL_GET_RECEIVE_TYPE =
//   'api/AwarenessPreferences/Page14/GetInformationReceiveType'
// export const URL_GET_INFORMATION_LANGUAGE =
//   'api/AwarenessPreferences/Page14/GetInformationLanguage'
// export const URL_GET_INFORMATION_ACCESSIBILITY =
//   'api/AwarenessPreferences/Page14/GetInformationAccessibilityNeeds'
// export const URL_GET_SUPPORT_DIRECTION_INFORMATION =
//   'api/AwarenessPreferences/Page14/GetSupportDirectionInformation'
export const URL_GET_SUPPORT_DIRECTION =
  'api/Resource/Page14/GetSupportDirection'
export const URL_GET_RECEIVE_TYPE =
  'api/Resource/Page14/GetInformationReceiveType'
export const URL_GET_INFORMATION_LANGUAGE =
  'api/Resource/Page14/GetInformationLanguage'
export const URL_GET_INFORMATION_ACCESSIBILITY =
  'api/Resource/Page14/GetInformationAccessibilityNeeds'
export const URL_GET_SUPPORT_DIRECTION_INFORMATION =
  'api/Resource/Page14/GetSupportDirectionInformation'
