// export const URL_VIOLENCE = 'api/Relationship/Page12/GetViolence'
// export const URL_NON_SAFETY_TYPES = 'api/Relationship/Page12/GetNonSafetyType'
// export const URL_CHILDREN_INTERACTING_TYPE =
//   'api/StatusAndMovement/Page13/GetChildrenInteractingType'
// export const URL_BAD_ATTITUDE_PERSON_TYPES =
//   'api/Relationship/Page12/GetBadAttitudePersonType'
// export const URL_BAD_ATTITUDE_TYPES =
//   'api/Relationship/Page12/GetBadAttitudeType'
// export const URL_BAD_ATTITUDE_DEMONSTRATION_TYPES =
//   'api/Relationship/Page12/GetBadAttitudeDemonstrationType'
export const URL_VIOLENCE = 'api/Resource/Page12/GetViolence'
export const URL_NON_SAFETY_TYPES = 'api/Resource/Page12/GetNonSafetyType'
export const URL_CHILDREN_INTERACTING_TYPE =
  'api/Resource/Page13/GetChildrenInteractingType'
export const URL_BAD_ATTITUDE_PERSON_TYPES =
  'api/Resource/Page12/GetBadAttitudePersonType'
export const URL_BAD_ATTITUDE_TYPES = 'api/Resource/Page12/GetBadAttitudeType'
export const URL_BAD_ATTITUDE_DEMONSTRATION_TYPES =
  'api/Resource/Page12/GetBadAttitudeDemonstrationType'
