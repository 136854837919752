import React, { useEffect, useRef } from 'react'
import { Form } from '../../form'
import { FamilyForm } from './family-member-form'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Info } from '../../../typography/info'
import { VerticalSpace } from '../../../space/vertical-space'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

const FamilyMemberData = ({
  fields,
  add,
  remove,
  isFamilyMember,
  parentFormItem,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <VerticalSpace style={{ marginBottom: '20px' }}>
        <Info
          style={{ alignItems: 'start' }}
          text={
            <>
              ՑՈՒՑՈՒՄ.«Գնահատման թիրախը տնային տնտեսությունը (ՏՏ) կամ ընտանիքն
              է։ Այս բառերը այս կոնտեքստում կիրառվում են փոխարինաբար։ Ընտանիքի
              կազմում ներառել (ՏՏ հիմնական կազմ) տնային տնտեսության այն
              անդամներին, ովքեր տեղահանման հետևանքով տվյալ վայրում են բնակվում,
              վարում են ընդհանուր տնային տնտեսություն, և իրենց համարում են
              ընտանիքի անդամը։ Լրացնել նաև (խնամյալ լրացուցիչ ՏՏ անդամ) այն
              անձանց տվյալները, ովքեր մինչև տեղահանումը ընտանիքի անդամ չեն
              հանդիսացել, բայց այժմ բնակվում են միասին, որպեսզի նրանց մասին
              ընտանիքը հոգ տանի, խնամի։
              <br />
              Չներառել (այլ վայրում բնակվող ՏՏ անդամ) ընտանիքի այն անդամների
              տվյալները, ովքեր ԼՂ-ում ապրելիս եղել են իրենց ընտանիքի անդամ,
              սակայն այժմ որևէ պատճառով ընտանիքի հետ համատեղ չեն բնակվում, քանի
              որ այդ տեղեկատվությունը լրացվելու է առանձին։ Չներառել նաև
              հյուրընկալ ընտանիքի անդամների տվյալները (եթե հյուրընկալ ընտանիքում
              է գտնվում, ովքեր տեղահանված անձինք չեն, և չեն ապրել հարցվող անձի
              հետ ԼՂ-ում միասին մեկ հարկի տակ)։
              <br />
              <br />
              <b>
                Ընտանիքի անդամների տվյալները լրացրեք` առաջինը նշելով գլխավոր
                հարցվողի տվյալները։
              </b>
            </>
          }
        />
      </VerticalSpace>
      <ManageFields fields={fields} add={add} />
      {fields.map((field) => (
        <React.Fragment key={field.name}>
          <FamilyForm
            field={field}
            remove={remove}
            parentFormItem={parentFormItem}
            isFamilyMember={isFamilyMember}
          />
        </React.Fragment>
      ))}
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={() => add()}
        style={{ margin: '20px auto' }}
      >
        <PlusCircleOutlined />
        {isFamilyMember
          ? 'Ավելացնել ընտանիքի անդամ'
          : 'Ավելացնել ոչ ընտանիքի անդամ'}
      </Button>
    </div>
  )
}

export const AddFamilyMember = ({
  viewMode = false,
  isFamilyMember = true,
}) => {
  return (
    <Form.List
      name={[
        'familyData',
        `${isFamilyMember ? 'familyMembers' : 'displacedPersons'}`,
      ]}
    >
      {(fields, { add, remove }) => (
        <FamilyMemberData
          viewMode={viewMode}
          fields={fields}
          add={add}
          remove={remove}
          isFamilyMember={isFamilyMember}
          parentFormItem={[
            'familyData',
            `${isFamilyMember ? 'familyMembers' : 'displacedPersons'}`,
          ]}
        />
      )}
    </Form.List>
  )
}
