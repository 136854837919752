// export const URL_ALL_STATES = '/api/Address/GetStates'
// export const URL_COMMUNITY_BY_STATE_ID = '/api/Address/GetCommunities'
// export const URL_ADDRESSES_BY_COMMUNITY_ID = '/api/Address/GetAddresses'
// export const URL_VALIDATE_PERSON = '/api/Nork/GetAvvPersonDataAsync'
// export const URL_GET_FIRST_PAGE_DATA = '/api/PersonConsent/GetPage1'
// export const URL_GET_SECOND_PAGE_DATA = '/api/FamilyInfo/GetPage2'
// export const URL_GET_THIRD_PAGE_DATA = '/api/CurrentLocality/GetPage3'
// export const URL_GET_FORTH_PAGE_DATA = '/api/CommunalLiving/GetPage4'
// export const URL_GET_FIFTH_PAGE_DATA = '/api/NutritionAssistance/GetPage5'
// export const URL_GET_SIXTH_PAGE_DATA = '/api/ProfessionalHealthcare/GetPage6'
// export const URL_GET_SEVENTH_PAGE_DATA = '/api/EducationalOrgAndCare/GetPage7'
// export const URL_GET_EIGHTH_PAGE_DATA = '/api/Activity/GetPage8'
// export const URL_GET_NINTH_PAGE_DATA = '/api/FamilyCoping/GetPage9'
// export const URL_GET_TENTH_PAGE_DATA = '/api/SupportNetwork/GetPage10'
// export const URL_GET_ELEVENTH_PAGE_DATA = '/api/FamilyAbilities/GetPage11'
// export const URL_GET_TWELFTH_PAGE_DATA = '/api/Relationship/GetPage12'
// export const URL_GET_THIRTEENTH_PAGE_DATA = '/api/StatusAndMovement/GetPage13'
// export const URL_GET_FOURTEEN_PAGE_DATA = '/api/AwarenessPreferences/GetPage14'
// export const URL_GET_FIFTEEN_PAGE_DATA = '/api/Summary/GetPage15'
export const URL_ALL_STATES = '/api/Address/GetStates'
export const URL_COMMUNITY_BY_STATE_ID = '/api/Address/GetCommunities'
export const URL_ADDRESSES_BY_COMMUNITY_ID = '/api/Address/GetAddresses'
export const URL_VALIDATE_PERSON = '/api/Nork/GetAvvPersonDataAsync'
// export const URL_GET_FIRST_PAGE_DATA = '/PersonConsent/GetPage1'
export const URL_GET_FIRST_PAGE_DATA = '/Questionnaire/GetPage1/GetPage1'
