// export const URL_FRIEND_SUPPORT_TYPE =
//   'api/SupportNetwork/Page10/GetFriendSupportType'
// export const URL_SUPPORT_ENTITY = 'api/SupportNetwork/Page10/GetSupportEntity'
// export const URL_SUPPORT_TYPE = 'api/SupportNetwork/Page10/GetSupportType'
// export const URL_RECEIPT_SUPPORT_TYPE =
//   'api/SupportNetwork/Page10/GetReceiptSupportType'
export const URL_FRIEND_SUPPORT_TYPE =
  'api/Resource/Page10/GetFriendSupportType'
export const URL_SUPPORT_ENTITY = 'api/Resource/Page10/GetSupportEntity'
export const URL_SUPPORT_TYPE = 'api/Resource/Page10/GetSupportType'
export const URL_RECEIPT_SUPPORT_TYPE =
  'api/Resource/Page10/GetReceiptSupportType'
