import React from 'react'
import { Form } from '../../../form/form'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { useCallback, useState } from 'react'
import { Radio, Spin } from 'antd'
import { FormSectionCard } from '../../../card/form-section-card'
import { FormItem } from '../../../form/form-item'
import { Checkbox } from '../../../checkbox'
import { URL_CHILDREN_INTERACTING_TYPE } from '../constants'
import { VerticalSpace } from '../../../space/vertical-space'
import { Info } from '../../../typography/info'

const ChildrenInteracting = () => {
  const { data, isLoading } = useGetQuestions(URL_CHILDREN_INTERACTING_TYPE)
  const form = Form.useFormInstance()
  const [supportStructures, setSupportStructures] = useState({})

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: [...parentName, itemName, 'id'],
          value: checked ? itemId : undefined,
        },
        {
          name: [...parentName, itemName, 'answerId'],
          value: checked ? undefined : undefined,
        },
      ])
    },
    [form],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <Checkbox
            style={{
              margin: '10px 0',
              display: 'flex',
            }}
            checked={state[itemName]}
            onChange={(e) =>
              handleCheckboxChange(
                itemName,
                e.target.checked,
                item.id,
                setState,
                parentName,
              )
            }
          >
            {item.name}
          </Checkbox>
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {!!(state[itemName] && level === 0) && (
            <FormItem
              name={[...parentName, itemName, 'answerId']}
              style={{ marginLeft }}
              rules={[{ required: true, message: 'Պարտադիր դաշտ' }]}
            >
              <Radio.Group style={{ margin: '10px 15px', display: 'block' }}>
                <VerticalSpace>
                  <Radio value={1}>
                    Այո, ես կամ ՏՏ այլ անդամ կիրառել ենք այս մեթոդը վերջին մեկ
                    ամսվա ընթացքում
                  </Radio>
                  <Radio value={2}>
                    Ոչ, ՏՏ-ում ոչ ոք չի կիրառել այս մեթոդը վերջին մեկ ամսվա
                    ընթացքում
                  </Radio>
                </VerticalSpace>
              </Radio.Group>
            </FormItem>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Ընտանիքի որևէ անդամ կիրառե՞լ է երեխաների հետ շփվելիս հետևյալ ձևերը"
      showRequired={true}
    >
      <Info
        style={{ marginBottom: 20 }}
        text="ՑՈՒՑՈՒՄ. Պատասխանների տարբերակները կարդալ, յուրաքանչյուր ենթահարցի համար ընտրել մեկ առավել համապատասխան տարբերակը։"
      />
      <Spin spinning={isLoading}>
        <FormItem
          name="householdChildrenInteractingTypes"
          rules={[
            {
              required: true,
              message: 'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
            },
          ]}
        >
          {renderItems(data, 0, supportStructures, setSupportStructures, [
            'householdChildrenInteractingTypes',
          ])}
        </FormItem>
      </Spin>
    </FormSectionCard>
  )
}

export default ChildrenInteracting
