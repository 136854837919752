import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { heatingSystemOptions, roomAppliances, waterSupply } from '../constatns'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Row } from 'antd'
import { SecondaryText, Text } from '../../typography'
import { COLORS } from '../../../helpers/constants'
import { Input } from '../../input'
import { capitalizeFirstLetter } from '../../../helpers/utils'
import { VerticalSpace } from '../../space/vertical-space'
import { Checkbox } from '../../checkbox'

const AccommodationUtility = () => {
  const appliancesChecklist = Form.useWatch('accommodationAppliances', {
    preserve: true,
  })
  return (
    <FormSectionCard
      title={
        'Արդյո՞ք Ձեր ներկայիս կացարանում առկա են հետևյալ կոմունալ-կենցաղային պայմանները'
      }
      showRequired={true}
    >
      <Row>
        <FormItem name="accommodationAppliances">
          {roomAppliances.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <FormItem
                  name={['accommodationAppliances', item.name]}
                  label={
                    <Text color={COLORS.PRIMARY.BLUE}>
                      {item.id}. {item.label}
                    </Text>
                  }
                >
                  <Radio.Group>
                    <FormItem
                      name={[
                        'accommodationAppliances',
                        item.name,
                        `has${capitalizeFirstLetter(item.name)}`,
                      ]}
                      rules={[{ required: true }]}
                    >
                      <Radio.Group>
                        <Radio value={true}>Այո</Radio>
                        <Radio value={false}>Ոչ</Radio>
                      </Radio.Group>
                    </FormItem>
                  </Radio.Group>
                  {/*Check for kitchen, sewage and bathroom  */}
                  {!!(
                    (appliancesChecklist?.bathroom?.hasBathroom &&
                      item.id === 1) ||
                    (appliancesChecklist?.kitchen?.hasKitchen && item.id === 4)
                  ) && (
                    <FormItem
                      name={['accommodationAppliances', item.name, `usedBy`]}
                      label={
                        <SecondaryText>Ում կողմից է օգտագործվում</SecondaryText>
                      }
                      style={{ marginLeft: '20px' }}
                      rules={[{ required: true }]}
                    >
                      <Radio.Group>
                        <VerticalSpace>
                          <Radio value={1}>
                            Օգտագործվում է միայն իր/իր ընտանիքի կողմից
                          </Radio>
                          <Radio value={2}>
                            Ոչ միայն իրենց ընտանիքի, այլ նաև այլ
                            անձանց/ընտանիքների կողմից (կումունալ)
                          </Radio>
                        </VerticalSpace>
                      </Radio.Group>
                    </FormItem>
                  )}
                  {/* Check for toilet */}
                  {!!(
                    appliancesChecklist?.toilet?.hasToilet && item.id === 3
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `location`,
                        ]}
                        label={<SecondaryText>Գտնվելու վայրը</SecondaryText>}
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>Կացարանի ներսում է</Radio>
                            <Radio value={2}>Կացարանից դուրս է</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `isDividedByGenders`,
                        ]}
                        label={
                          <SecondaryText>
                            Զուգարանը առանձնացվա՞ծ է ըստ սեռերի
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>
                              Առանձնացված է տղամարդկանց և կանանց համար
                            </Radio>
                            <Radio value={2}>
                              Առանձնացված չէ տղամարդկանց և կանանց համար
                            </Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                      <FormItem
                        name={['accommodationAppliances', item.name, `usedBy`]}
                        label={
                          <SecondaryText>
                            Ում կողմից է օգտագործվում
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>
                              Օգտագործվում է միայն իր/իր ընտանիքի կողմից
                            </Radio>
                            <Radio value={2}>
                              Օգտագործվում է ոչ միյան իրենց ընտանիքի, այլ նաև
                              այլ անձանց/ընտանիքների կողմից (կոմունալ)
                            </Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                  {!!(appliancesChecklist?.WC?.hasWC && item.id === 5) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `location`,
                        ]}
                        label={<SecondaryText>Գտնվելու վայրը</SecondaryText>}
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Checkbox.Group>
                          <VerticalSpace>
                            <Checkbox value={1}>Խոհանոցում</Checkbox>
                            <Checkbox value={2}>Բաղնիքում</Checkbox>
                            <Checkbox value={3}>Այլ</Checkbox>
                          </VerticalSpace>
                        </Checkbox.Group>
                      </FormItem>
                      <FormItem
                        name={['accommodationAppliances', item.name, `usedBy`]}
                        label={
                          <SecondaryText>
                            Ում կողմից է օգտագործվում
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>
                              Օգտագործվում է միայն իր/իր ընտանիքի կողմից
                            </Radio>
                            <Radio value={2}>
                              Օգտագործվում է ոչ միյան իրենց ընտանիքի, այլ նաև
                              այլ անձանց/ընտանիքների կողմից (կոմունալ)
                            </Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                  {/* Check for bed and bedroom */}
                  {!!(
                    (appliancesChecklist?.bedroom?.hasBedroom &&
                      item.id === 11) ||
                    (appliancesChecklist?.bed?.hasBed && item.id === 12)
                  ) && (
                    <FormItem
                      name={[
                        'accommodationAppliances',
                        item.name,
                        `sufficiency`,
                      ]}
                      label={
                        <SecondaryText>
                          {item.id === 11
                            ? 'Արդյո՞ք տարածքը'
                            : 'Արդյո՞ք այն․․․'}
                        </SecondaryText>
                      }
                      style={{ marginLeft: '20px' }}
                      rules={[{ required: true }]}
                    >
                      <Radio.Group>
                        <VerticalSpace>
                          <Radio value={1}>
                            Բավարար է ընտանիքի բոլոր անդամների համար
                          </Radio>
                          <Radio value={2}>
                            Բավարար չէ ընտանիքի բոլոր անդամների համար
                          </Radio>
                        </VerticalSpace>
                      </Radio.Group>
                    </FormItem>
                  )}
                  {/* Check for heating system */}
                  {!!(
                    appliancesChecklist?.heatingSystem?.hasHeatingSystem &&
                    item.id === 15
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `sufficiency`,
                        ]}
                        label={
                          <SecondaryText>
                            Արդյո՞ք բավարար է ջեռուցվում կացարանը
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>Բավարար է</Radio>
                            <Radio value={2}>Բավարար չէ</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                      {appliancesChecklist?.heatingSystem?.sufficiency ===
                        2 && (
                        <FormItem
                          name={['accommodationAppliances', item.name, `needs`]}
                          rules={[{ required: true }]}
                          style={{ marginLeft: '20px' }}
                        >
                          <Checkbox.Group
                            options={heatingSystemOptions}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                              marginLeft: '40px',
                            }}
                          />
                        </FormItem>
                      )}
                      {!!(
                        Array.isArray(
                          appliancesChecklist?.heatingSystem?.needs,
                        ) &&
                        appliancesChecklist?.heatingSystem?.needs.includes(4)
                      ) && (
                        <FormItem
                          name={[
                            'accommodationAppliances',
                            item.name,
                            `heatingSystemComment`,
                          ]}
                          style={{ marginLeft: '20px' }}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </FormItem>
                      )}
                    </>
                  )}
                  {!!(
                    (appliancesChecklist?.hotDrinkingWater
                      ?.hasHotDrinkingWater &&
                      item.id === 7) ||
                    (appliancesChecklist?.coldDrinkingWater
                      ?.hasColdDrinkingWater &&
                      item.id === 8)
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `permanency`,
                        ]}
                        label={
                          <SecondaryText>Արդյո՞ք ջուրը մշտական է</SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>Այո</Radio>
                            <Radio value={2}>Ոչ</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                      <FormItem
                        name={['accommodationAppliances', item.name, `usedBy`]}
                        label={
                          <SecondaryText>
                            Ում կողմից է օգտագործվում
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>
                              Օգտագործվում է միայն իր/իր ընտանիքի կողմից
                            </Radio>
                            <Radio value={2}>
                              Օգտագործվում է ոչ միյան իրենց ընտանիքի, այլ նաև
                              այլ անձանց/ընտանիքների կողմից (կոմունալ)
                            </Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                      <FormItem
                        name={['accommodationAppliances', item.name, `isClean`]}
                        label={
                          <SecondaryText>
                            Արդյո՞ք ջուրը մաքուր է և պիտանի խմելու համար
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={true}>Այո</Radio>
                            <Radio value={false}>Ոչ</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                  {/* Drinking water supply */}
                  {!!(
                    appliancesChecklist?.coldDrinkingWater
                      ?.hasColdDrinkingWater === false && item.id === 8
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `waterSupply`,
                        ]}
                        label={
                          <SecondaryText>
                            Ինչպես եք ապահովում խմելու ջուր Ձեր և Ձեր ընտանիքի
                            անդամների համար
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Checkbox.Group
                          options={waterSupply}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                          }}
                        />
                      </FormItem>
                      {Array.isArray(
                        appliancesChecklist?.coldDrinkingWater?.waterSupply,
                      ) &&
                        appliancesChecklist?.coldDrinkingWater?.waterSupply.includes(
                          5,
                        ) && (
                          <FormItem
                            name={[
                              'accommodationAppliances',
                              item.name,
                              `waterSupplyComment`,
                            ]}
                            style={{ marginLeft: '20px' }}
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </FormItem>
                        )}
                    </>
                  )}
                  {!!(
                    (appliancesChecklist?.coldWaterHousehold
                      ?.hasColdWaterHousehold === true &&
                      item.id === 9) ||
                    (appliancesChecklist?.hotWaterHousehold
                      ?.hasHotWaterHousehold === true &&
                      item.id === 10)
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `isPermanent`,
                        ]}
                        label={
                          <SecondaryText>Արդյո՞ք ջուրը մշտական է</SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>Այո</Radio>
                            <Radio value={2}>Ոչ</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                  {!!(
                    appliancesChecklist?.ventilation?.hasVentilation &&
                    item.id === 16
                  ) && (
                    <>
                      <FormItem
                        name={[
                          'accommodationAppliances',
                          item.name,
                          `sufficiency`,
                        ]}
                        label={
                          <SecondaryText>
                            Արդյո՞ք բավարար է օդափոխությունը
                          </SecondaryText>
                        }
                        style={{ marginLeft: '20px' }}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group>
                          <VerticalSpace>
                            <Radio value={1}>Այո</Radio>
                            <Radio value={2}>Ոչ</Radio>
                            <Radio value={3}>Դժվարանում է պատասխանել</Radio>
                          </VerticalSpace>
                        </Radio.Group>
                      </FormItem>
                    </>
                  )}
                </FormItem>
              </React.Fragment>
            )
          })}
        </FormItem>
      </Row>
    </FormSectionCard>
  )
}

export default AccommodationUtility
