import React, { useCallback, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Title } from '../../typography'
import { FormItem } from '../../form/form-item'
import useGetQuestions from '../../../api/application/use-get-questions'
import {
  GET_OBSERVATION_DIRECTION,
  observationDirectionAnswers,
} from './constants'
import { Form, Spin } from 'antd'
import { COLORS } from '../../../helpers/constants'
import { Checkbox } from '../../checkbox'

const createCheckboxName = (questionId, itemId) =>
  `question_${questionId}_item_${itemId}`

export const ObservationDirection = () => {
  const [incomeTypeValue] = useState([])
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  form.setFieldsValue({ incomeTypeValue: incomeTypeValue })

  const { data: questions, isLoading } = useGetQuestions(
    GET_OBSERVATION_DIRECTION,
  )
  const handleCheckboxChange = useCallback(
    async (questionId, answerId, checked, itemName) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [questionId]: {
          ...prevCheckedIds[questionId],
          [itemName]: checked,
        },
      }))

      const currentValue =
        form.getFieldValue('householdObservationDirections') || []
      const updatedValue = checked
        ? [...currentValue, { id: questionId, answerId }]
        : currentValue.filter((item) => item && item.id !== questionId)
      form.setFieldsValue({ householdObservationDirections: updatedValue })
      try {
        await form.validateFields([
          `householdObservationDirections_${questionId}`,
        ])
      } catch (error) {
        return
      }
    },
    [form],
  )

  const validateCheckboxGroup = (questionId) => {
    const questionCheckedIds = checkedIds[questionId] || {}
    return Object.values(questionCheckedIds).some((isChecked) => isChecked)
  }

  const renderItems = (items, question) => {
    return items.map((item) => {
      const itemName = createCheckboxName(question.id, item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[
              'householdObservationDirections',
              question.id,
              item.id,
              'answerId',
            ]}
            rules={[
              {
                validator: () => {
                  return validateCheckboxGroup(question.id)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      )
                },
              },
            ]}
          >
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(
                  question.id,
                  item.id,
                  e.target.checked,
                  itemName,
                )
              }}
            >
              {item.name}
            </Checkbox>
          </FormItem>
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard title="Դիտարկման արդյունքները" showRequired={true}>
      <Spin spinning={isLoading}>
        <Title>
          Սոցիալական աշխատողի կողմից իրականացվող դիտարկման ուղղությունները
        </Title>
        <Title style={{ marginBottom: 20 }}>Փոխհարաբերությունները </Title>
        {questions.map((question) => {
          return (
            <FormItem name={'householdObservationDirections'} key={question.id}>
              <input hidden />
              <React.Fragment>
                <Title
                  style={{ marginTop: 20, marginBottom: 20 }}
                  color={COLORS.PRIMARY.BLUE}
                >
                  {question.id}. {question.name}
                  <span
                    style={{
                      color: COLORS.ALERT.RED,
                      marginLeft: '5px',
                      fontWeight: 100,
                    }}
                  >
                    *
                  </span>
                </Title>
                {renderItems(observationDirectionAnswers, question)}
              </React.Fragment>
            </FormItem>
          )
        })}
      </Spin>
    </FormSectionCard>
  )
}
